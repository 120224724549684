@import "./global.sass"

.App
  display: flex
  flex-direction: column
  overflow: hidden

header
  padding: 24px 24px 24px 32px
  display: flex

  > img
    height: 60px
    pointer-events: none

main
  max-width: 1100px
  min-height: calc(100vh - 320px)
  margin: 0 auto

footer
  background-color: black
  padding-top: 16px
  padding-bottom: 16px
  color: #333
  display: flex
  flex-direction: column
  align-items: center
  padding: 64px 24px

  > .social
    display: flex
    flex-direction: row
    margin-bottom: 24px

    > a
      display: flex
      padding: 8px
      border: 2px solid white
      border-radius: 22px

      &:not(:last-child)
        margin-right: 16px

      > img
        width: 24px
        height: 24px
        pointer-events: none

  > div
    font-size: 13px
    color: white
    line-height: 16px

// .result, .content
//   background-color: #EB3F42

// .button-group
//   display: inline-block

//   img
//     height: 48px
//     padding: 0 4px

h2
  font-size: 24px
  font-weight: bold
  line-height: 32px
  margin: 28px 0 24px

  @include mobile
    margin: 24px 0 18px

h3
  font-size: 18px
  font-weight: bold
  line-height: 28px

  @include mobile
    margin: 8px 0

a:hover
  opacity: 0.5

.card
  display: flex
  padding: 8px
  background-color: white
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.1)
  border-radius: 4px

  .media-mask
    display: flex
    flex-direction: column
    border-radius: 4px
    overflow: hidden
    transform: translateZ(0)

    .carousel-root
      width: 400px

      @include mobile
        width: 240px
    
    .carousel-slider

      img
        object-fit: cover
        width: 400px
        height: 400px

        @include mobile
          width: 240px
          height: 240px

    .thumbs-wrapper
      margin: 0

      ul.thumbs
        margin: 4px 0 0
        padding: 0

        li.thumb
          margin-right: 2px

          &.selected
            // border-color: red

      img
        object-fit: cover
        width: 56px
        height: 56px

.description
  font-size: 17px
  line-height: 23px
  padding-top: 16px
  padding-bottom: 16px

  > h2:first-child
    margin-top: 0

  > p, li
    line-height: 28px

.button
  display: inline-block
  font-size: 20px
  border-radius: 8px
  padding: 12px 24px
  background-color: $qubic-orange
  border: 1px solid $qubic-orange
  color: white
  text-decoration: none
  cursor: pointer

  &.outline
    background-color: white
    border: 1px solid $qubic-orange
    color: $qubic-orange

  &.disabled
    opacity: 0.4
    pointer-events: none

// .result
//   padding-bottom: 36px

//   > .thanks
//     font-size: 22px
//     line-height: 32px
//     padding-top: 24px

.content
  display: flex
  margin: 64px 54px 88px
  flex-direction: column

  @include desktop
    flex-direction: row

  @include mobile
    margin: 32px 27px 44px  

  > div
    display: flex
    flex-direction: row
    justify-content: center
    align-items: flex-start

    &:not(:last-child)
      margin-right: 0
      margin-bottom: 56px
      
      @include desktop
        margin-right: 56px
        margin-bottom: 0

      @include mobile
        margin-bottom: 36px

.asset
  position: relative
  display: flex
  flex-direction: row

  > .left-bg
    position: absolute
    z-index: -999
    top: -50px
    left: -160px

    @include mobile
      top: -30px
      left: -100px

    > img
      width: 240px
      height: 240px

      @include mobile
        width: 160px
        height: 160px

  .redeemed
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    background-color: rgba(0, 0, 0, 0.5)
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    color: white
    font-size: 16px

    > *:not(:last-child)
      margin-bottom: 12px

    > img
      width: 40px
      height: 40px

.info
  position: relative
  flex: 1
  display: flex
  flex-direction: column
  justify-content: center

  @include tablet-only
    // max-width: 480px

  @include mobile
    // max-width: 280px

  > .right-bg
    position: absolute
    z-index: -999
    right: -180px
    bottom: -200px

    @include mobile
      right: -150px
      bottom: -80px

    > img
      width: 400px
      height: 400px

      @include mobile
        width: 267px
        height: 267px

  > .download
    padding-top: 24px
  
  > .button-group
    padding: 24px 0
    
    > a
      display: inline-block
      margin-bottom: 16px

      @include mobile
        display: block
        text-align: center

      &:not(:last-child)
        margin-right: 16px

        @include mobile
          margin-right: 0px

.modal
  position: fixed
  top: 0
  left: 0
  right: 0
  bottom: 0
  display: flex
  justify-content: center
  align-items: center
  padding: 32px

  > .lightbox
    background-color: rgba(0, 0, 0, 0.4)
    position: fixed
    top: 0
    left: 0
    right: 0
    bottom: 0
    z-index: 1

  > .card
    display: flex
    flex-direction: column
    max-width: 400px
    padding: 32px
    z-index: 2

    > h2
      margin-top: 8px

    > div
      margin-top: 12px
      font-size: 16px

    > input
      margin-top: 8px
      padding: 8px 12px
      font-size: 16px
      border: 1px #CCC solid
      border-radius: 4px

    > .submit
      display: flex
      justify-content: right
      padding-top: 16px

    .button
      font-size: 16px
      padding: 8px 12px

// .redeem
//   padding-top: 24px
//   padding-bottom: 12px

// /* redeem-button

// .redeem-button
//   position: relative
//   display: inline-block

// .redeem-button-bg
//   width: 100%
//   height: 100%
//   position: absolute

// .redeem-button-bg1, .redeem-button-bg2
//   width: 100%
//   height: 100%
//   position: absolute
//   transform: skew(-45deg)

// .redeem-button-bg1
//   background-color: #EB3F42
//   left: 4px
//   top: 4px

// .redeem-button-bg2
//   background-color: black

// .redeem-button-text
//   position: relative
//   font-size: 20px
//   padding-top: 8px
//   padding-bottom: 8px
//   padding-left: 36px
//   padding-right: 36px
